import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { MediaQuery } from "@jmc/solid-design-system/src/components/atoms/MediaQuery/MediaQuery";
import { Card } from "@jmc/solid-design-system/src/components/molecules/Card/Card";
import { WarningMessage } from "@jmc/solid-design-system/src/components/atoms/WarningMessage/WarningMessage";
import { AudioPlayer } from "@jmc/solid-design-system/src/components/organisms/AudioPlayer/AudioPlayer";
import React, { Fragment } from "react";
import classnames from "classnames";
import { getNextBreakPoint } from "../../../utils/breakpoints";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import style from "./style.module.scss";

interface Props {
    brightcove_id?: string;
    showTitle: boolean;
    showDescription: boolean;
    showStreamingOptions?: boolean;
    streamingOptionsSectionTitle?: string | JSX.Element | JSX.Element[];
    dropShadow: boolean;
    fileUrl: string;
    image?: string | JSX.Element;
    imageBreakpoint?: "xs" | "sm" | "md" | "lg";
    title?: string | JSX.Element | JSX.Element[];
    descriptionColor?: "primary" | "gray";
    streamingOptions?: JSX.Element[];
    children?: React.ReactNode;
    mediastop?: (playing: boolean, currentTime: number | string) => void;
    warning?: string;
    cookieMessage?: string;
    downloadLink?: JSX.Element;
    playButtonAriaLabel?: string;
    pauseButtonAriaLabel?: string;
    replayButtonAriaLabel?: string;
    backButtonAriaLabel?: string;
    forwardButtonAriaLabel?: string;
    addBorder?: boolean;
    publicationDate?: JSX.Element;
    isOnDetailPage?: boolean;
    isTransparent?: boolean;
}

export const AudioCard = (props: Props): JSX.Element => {
    const {
        showTitle = true,
        showDescription = true,
        showStreamingOptions = false,
        streamingOptions = null,
        streamingOptionsSectionTitle = "",
        dropShadow = true,
        fileUrl,
        image = null,
        imageBreakpoint = null,
        title = null,
        descriptionColor = "primary",
        children = null,
        mediastop = null,
        warning = "",
        cookieMessage = "",
        downloadLink,
        addBorder = false,
        publicationDate = null,
        isOnDetailPage = false,
        isTransparent = false,
        ...other
    } = props;

    const { jnjFullBranded } = useJnjBranding();

    const warningBlock = (
        <div className={style.blocked}>
            <div className={style.warning}>
                <WarningMessage warning={warning} cookieMessage={cookieMessage} variant="card" />
            </div>
        </div>
    );

    const footer = (
        <>
            <div
                className={classnames(
                    style.audioCardFooter,
                    jnjFullBranded && isOnDetailPage ? style.detailPage : null,
                )}
            >
                <AudioPlayer
                    src={fileUrl}
                    mediastop={mediastop}
                    isOnDetailPage={isOnDetailPage}
                    downloadLink={downloadLink}
                    {...other}
                />
                {!showStreamingOptions && downloadLink && <div className={style.downloadLink}>{downloadLink}</div>}
            </div>

            {showStreamingOptions && (
                <>
                    <div className={style.streamingOptionsWrapper}>
                        <div className={style.streamingLabel}>
                            <Typography size="m" weight="400" color="light">
                                {streamingOptionsSectionTitle}
                            </Typography>
                        </div>
                        <div className={style.streamingOptions}>{streamingOptions}</div>
                    </div>
                    {jnjFullBranded && downloadLink && <div className={style.downloadLinkWrapper}>{downloadLink}</div>}
                </>
            )}
        </>
    );

    if (imageBreakpoint) {
        const nextBreakpoint = getNextBreakPoint(imageBreakpoint);

        return (
            <Fragment>
                <MediaQuery className={style.audioCardWrapper} max={imageBreakpoint}>
                    <Card
                        title={showTitle && title}
                        fullBackground={false}
                        dropShadow={dropShadow}
                        addBorder={addBorder}
                        footer={footer}
                        publicationDate={publicationDate}
                        isOnDetailPage={isOnDetailPage}
                        isTransparent={isTransparent}
                        {...other}
                        idPrefix="AudioCard"
                    >
                        <Typography color={descriptionColor}>{showDescription && children}</Typography>
                    </Card>
                </MediaQuery>
                <MediaQuery className={style.audioCardWrapper} min={nextBreakpoint}>
                    <Card
                        title={showTitle && title}
                        fullBackground={false}
                        dropShadow={dropShadow}
                        addBorder={addBorder}
                        image={image}
                        footer={footer}
                        publicationDate={publicationDate}
                        {...other}
                        idPrefix="AudioCard"
                        cookieMessage={warning && warningBlock}
                        isOnDetailPage={isOnDetailPage}
                        isTransparent={isTransparent}
                    >
                        {showDescription && <Typography color={descriptionColor}>{children}</Typography>}
                    </Card>
                </MediaQuery>
            </Fragment>
        );
    } else {
        return jnjFullBranded && isOnDetailPage ? (
            <div>
                <Card
                    title={showTitle && title}
                    fullBackground={false}
                    dropShadow={dropShadow}
                    addBorder={addBorder}
                    image={image}
                    publicationDate={publicationDate}
                    isOnDetailPage={isOnDetailPage}
                    {...other}
                    idPrefix="AudioCard"
                    cookieMessage={warning && warningBlock}
                    isTransparent={true}
                >
                    <Typography color={descriptionColor}>{showDescription && children}</Typography>
                </Card>
                {footer}
            </div>
        ) : (
            <Card
                title={showTitle && title}
                fullBackground={false}
                dropShadow={dropShadow}
                addBorder={addBorder}
                image={image}
                footer={!jnjFullBranded ? footer : undefined}
                publicationDate={publicationDate}
                {...other}
                idPrefix="AudioCard"
                cookieMessage={warning && warningBlock}
                isOnDetailPage={isOnDetailPage}
                isTransparent={isTransparent}
            >
                <Typography color={descriptionColor}>{showDescription && children}</Typography>
                {jnjFullBranded && footer}
            </Card>
        );
    }
};
