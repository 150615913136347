import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Card } from "@jmc/solid-design-system/src/components/molecules/Card/Card";
import React from "react";
import loadable from "@loadable/component";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

const Html = loadable(() => import("../../molecules/Html/Html"));

interface Props {
    mediasource?: string;
    video: JSX.Element;
    showTitle?: boolean;
    showDescription?: boolean;
    dropShadow?: boolean;
    title: string;
    description: string;
    children?: React.ReactNode;
    descriptionColor?: "primary" | "gray";
    seoTitle?: string;
    alt?: string;
}

export const VideoCard = (props: Props): JSX.Element => {
    const {
        showTitle = true,
        showDescription = true,
        dropShadow = true,
        title,
        description,
        descriptionColor = "primary",
        seoTitle,
        video = undefined,
        ...other
    } = props;

    const { jnjFullBranded } = useJnjBranding();

    return (
        <Card
            title={!showTitle ? undefined : title || seoTitle}
            dropShadow={jnjFullBranded ? false : dropShadow}
            {...other}
            video={video}
            idPrefix="VideoCard"
            isTransparent={jnjFullBranded}
        >
            {showDescription && (
                <Typography color={descriptionColor}>
                    <Html color="inherit">{description}</Html>
                </Typography>
            )}
        </Card>
    );
};
